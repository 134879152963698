import { render, staticRenderFns } from "./DefaultPageBlockComponent.vue?vue&type=template&id=f8e3574c&scoped=true&lang=pug"
import script from "./DefaultPageBlockComponent.vue?vue&type=script&lang=js"
export * from "./DefaultPageBlockComponent.vue?vue&type=script&lang=js"
import style0 from "./DefaultPageBlockComponent.vue?vue&type=style&index=0&id=f8e3574c&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f8e3574c",
  null
  
)

export default component.exports