import { render, staticRenderFns } from "./ReconnectMarketingOnboarding.vue?vue&type=template&id=4264e9c3&scoped=true&lang=pug"
import script from "./ReconnectMarketingOnboarding.vue?vue&type=script&lang=js"
export * from "./ReconnectMarketingOnboarding.vue?vue&type=script&lang=js"
import style0 from "./ReconnectMarketingOnboarding.vue?vue&type=style&index=0&id=4264e9c3&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4264e9c3",
  null
  
)

export default component.exports